/**
 * Web app for the "index" page
 * 17.7.2022 KC
 */
import Vue from 'vue';
import {BootstrapVue} from 'bootstrap-vue';
import $ from 'jquery';
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from './store';

// Import components
import appFile from './components/index-root.vue';

Vue.component('IndexRoot', appFile);

// Ferropoly Style!
import '../common/style/app.scss';
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);


$(document).ready(function () {
  console.log('DOM ready');
  new Vue({
    el     : '#index-app',
    created: function () {
      console.log('created');
    },
    store: store
  });
});

