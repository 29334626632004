<template lang="pug">
  #wrap
    menu-bar(show-user-box=false :elements-right="menuElementsRight")
    div.centered(v-if="action === 'init'")
      div.centered
        b-spinner(type="grow" variant="warning")
    .content-box.ls-box-shape(v-if="action !== 'init'")
      div(v-if="title.length < 1")
        h1 Ferropoly Login Verwaltung
        p Du möchtest einen kostenlosen Zugang zum Ferropoly oder Dein Passwort eines bestehenden Zugangs ändern?
        p Bitte gib Deine Email-Adresse in das folgende Feld ein, dann bekommst Du die Infos mit dem Link zur Bearbeitungsseite in Deinem Postfach:
        b-input(:disabled="authSessionReady" v-model="email" type="email")
        b-button.mt-2(@click="start" variant="primary" :disabled="authSessionReady || !validateEmail || requestSubmitted") Los geht's

      div(v-if="title.length > 0")
        h1 {{title}}
        p.mb-5 {{message}}
        p Du kannst diese Seite nun schliessen. Bis bald!

</template>

<script>
import axios from 'axios';
import {mapFields} from 'vuex-map-fields';
import {get, delay} from 'lodash'
import MenuBar from '../../common/components/menu-bar/menu-bar.vue'

export default {
  name      : 'IndexRoot',
  components: {MenuBar},
  data      : function () {
    return {
      menuElementsRight: [
        {title: 'Impressum / Kontakt', href: 'https://www.ferropoly.ch', hide: false}
      ],
      requestSubmitted : false,
      action           : 'init'
    };
  },
  computed  : {
    ...mapFields({
          authSession: 'authSession',
          email      : 'email',
          title      : 'response.title',
          message    : 'response.message'
        }
    ),
    authSessionReady() {
      console.log('authSession', this.authSession);
      return (this.authSession === 'none');
    },
    validateEmail() {
      let valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email);
      console.log('email', valid);
      return valid;
    }
  },
  created   : function () {
    let self = this;
    axios.get('/auth')
        .then(resp => {
          console.log('new auth session', resp.data._id)
          this.authSession = resp.data._id;
          delay(() => {
            self.action = 'start';
          }, 2000);
        })
        .catch(err => {
          console.error(err);
        })
  },
  methods   : {
    start: function () {
      this.requestSubmitted = true;
      axios.post('/start', {id: this.authSession, email: this.email})
          .then(resp => {
            this.title   = get(resp, 'data.title', 'Ferropoly');
            this.message = get(resp, 'data.message', '');
          })
          .catch(err => {
            console.error('fail', err);
            this.title   = get(err, 'response.data.title', 'Ferropoly');
            this.message = get(err, 'response.data.message', '');
          })
    }
  }
}
</script>

<style scoped>
body {
  /* background-image: url("/images/ferropoly_background01.jpg");*/
  background-clip: border-box;
  background-size: cover;
}

.content-box {
  max-width: 66%;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}


.ls-box-shape {
  padding: 15px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 5px;
  margin-top: 30px;
}
</style>
