var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("menu-bar", { attrs: { "elements-right": _vm.menuElementsRight } }),
      _vm.action === "init"
        ? _c("div", { staticClass: "centered" }, [
            _c(
              "div",
              { staticClass: "centered" },
              [
                _c("b-spinner", {
                  attrs: { type: "grow", variant: "warning" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.action !== "init"
        ? _c("div", { staticClass: "content-box ls-box-shape" }, [
            _vm.title.length < 1
              ? _c(
                  "div",
                  [
                    _c("h1", [_vm._v("Ferropoly Login Verwaltung")]),
                    _c("p", [
                      _vm._v(
                        "Du möchtest einen kostenlosen Zugang zum Ferropoly oder Dein Passwort eines bestehenden Zugangs ändern?"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Bitte gib Deine Email-Adresse in das folgende Feld ein, dann bekommst Du die Infos mit dem Link zur Bearbeitungsseite in Deinem Postfach:"
                      ),
                    ]),
                    _c("b-input", {
                      attrs: { disabled: _vm.authSessionReady, type: "email" },
                      model: {
                        value: _vm.email,
                        callback: function ($$v) {
                          _vm.email = $$v
                        },
                        expression: "email",
                      },
                    }),
                    _c(
                      "b-button",
                      {
                        staticClass: "mt-2",
                        attrs: {
                          variant: "primary",
                          disabled:
                            _vm.authSessionReady ||
                            !_vm.validateEmail ||
                            _vm.requestSubmitted,
                        },
                        on: { click: _vm.start },
                      },
                      [_vm._v("Los geht's")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.title.length > 0
              ? _c("div", [
                  _c("h1", [_vm._v(_vm._s(_vm.title))]),
                  _c("p", { staticClass: "mb-5" }, [
                    _vm._v(_vm._s(_vm.message)),
                  ]),
                  _c("p", [
                    _vm._v("Du kannst diese Seite nun schliessen. Bis bald!"),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }