/**
 * Store for the index
 * Christian Kuster, CH-8342 Wernetshausen, christian@kusti.ch
 * Created: 12.09.22
 **/
import Vue from 'vue';
import Vuex from 'vuex';
import {getField, updateField} from 'vuex-map-fields';

Vue.use(Vuex);

const store = new Vuex.Store({
  state    : {
    email      : '',
    authSession: 'none',
    response   : {
      title  : '',
      message: ''
    }
  },
  getters  : {getField},
  mutations: {updateField},
  actions  : {}
});


export default store;
